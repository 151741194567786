import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { buildSellerWizardPageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { Card } from 'antd';
import { Page } from '../../components/layout';
import Footer from '../../components/layout/Footer';
import HeroSection from '../SellLandingPage/Components/HeroSection';
import styles from './FundsLandingPage.module.scss';
import { setSelectedAddress } from '../../reducers/SellerWizard/actions';
import FAQSection from '../HomeownersLandingPage/Components/FAQSection';
import { ZorbaButton } from '../../components/forms';
import HowItWorksSection from '../HomeownersLandingPage/Components/HowItWorksSection';

export default () => {
  const [placeValue, setPlaceValue] = useState(undefined);
  const [placesLoader, setPlacesLoader] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const onPlaceSelect = (val) => {
    setPlaceValue(val);
    dispatch(setSelectedAddress(val));
    if (!val) return;
    setPlacesLoader(true);

    setTimeout(() => {
      router.push(buildSellerWizardPageUrl());
      setPlacesLoader(false);
      setPlaceValue();
    }, 2000);
  };
  const inheritedProps = {
    placeValue,
    setPlaceValue,
    setPlacesLoader,
    placesLoader,
    onPlaceSelect,
  };
  return (
    <Page
      className={styles['agent-landing-page']}
      pageInner={styles['page-inner']}
      pageInnerContent={styles['page-inner-content']}
      footer={<Footer />}
    >
      <HeroSection
        {...inheritedProps}
        title="The SFR and BTR investment platform. Powered by AI, managed by experts."
        desc="Source, acquire, renovate, lease, and manage single-family rentals all from one place with streamlined workflows powered by AI."
        img="/img/landing-pages/funds/fund-hero-visual.png"
        cta={
          <ZorbaButton
            onClick={() => {
              window.location.href = 'mailto:data@getzorba.com';
              // document.getElementById('contact').scrollIntoView()
            }}
            className={styles['cta-btn']}
            fullWidth={false}
          >
            Request a demo
          </ZorbaButton>
        }
      />

      <div className={styles['mid-section']}>
        <div className={styles.left}>
          <h3>The modern SFR and BTR investment experience</h3>
          <p>Zorba is the all-in-one SFR and BTR platform that streamlines and automates the entire workflow for you, allowing you to focus on what matters most.</p>
        </div>
        <div className={styles.right}>
          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Source & Acquire</h5>
            <p className={styles.desc}>
              Our proprietary data and analytics tools are utilized to identify under-valued properties which our experts can underwrite in under 90 seconds with our advanced AI-driven technology.
            </p>
          </div>
          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Renovate</h5>
            <p className={styles.desc}>
              Our local renovation partners elevate the properties to first-rate standards.
            </p>
          </div>          
          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Lease</h5>
            <p className={styles.desc}>
              Our property management partners secure long-term, high-quality tenants.
            </p>
          </div>

          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Manage</h5>
            <p className={styles.desc}>
            Our extensive  local network delivers a first-rate tenant experience and outstanding  returns on investment.
            </p>
          </div>
        </div>
      </div>

      <HowItWorksSection
        {...inheritedProps}
        frontImg="/img/landing-pages/funds/phone-funds.png"
        className={styles['how-it-works']}
        cta={
          <div className={styles['bottom-cta']}>
            <h3>
              Evolve your SFR & BTR strategy today
            </h3>
            <ZorbaButton
              // variant={BUTTON_VARIANT_DARK}
              className={styles['cta-btn-bottom']}
              fullWidth={false}
              onClick={() =>
                // document.getElementById('contact').scrollIntoView()
                (window.location.href = 'mailto:data@getzorba.com')
              }
            >
              Invest with Zorba
            </ZorbaButton>
          </div>
        }
        variantData={{
          howItWorksItems: [
            {
              title: 'Buy Box',
              desc: 'Customize your buying preferences to fit your investment strategy and goals.',
            },
            {
              title: 'Access',
              desc: 'Match with target off-market properties 24/7. Our algorithm uses 200+ institutional-grade data sources to identify properties that match your Buy Box criteria, nationwide.',
            },
            {
              title: 'Automated Underwriting',
              desc: 'Zorba’s exclusive automated underwriting model analyzes a property in just seconds instead of days, saving you valuable time on each offer without compromising quality or security.',
            },
            {
              title: 'Transact',
              desc: 'Leverage the power of Zorba as your national brokerage to purchase properties more quickly and knowledgeably than ever before.',
            },
            {
              title: 'Integrate',
              desc: 'Zorba provides everything you need to successfully operate properties at scale, including inspections, title, financing, and property management.',
            },
          ],
        }}
      />

      <div className={styles.contact} id="contact">
        <div className={styles.left}>{/*  */}</div>
        <div className={styles.right}>
          <p>
            Talk to our team today to learn more about what Zorba offers for
            SFR & BTR Owners and Operators
          </p>
          <h3>
            <a href="mailto:owners@getzorba.com">
              owners@getzorba.com
            </a>
          </h3>
        </div>
      </div>
    </Page>
  );
};
